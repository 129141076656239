import * as React from "react";
// @ts-ignore
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Img from "gatsby-image";
import styled from "styled-components";
// @ts-ignore
import Fade from "react-reveal/Fade";
import { Icon } from "antd";

import { Title, Para } from "../basic";
import { space, breakpoints } from "../../theme";

interface Props {
  title: string;
  subtitle: string;
  fullwidth: "true" | "false";
  highres: string;
  aspectratio: string;
  aspectRatio: number;
  src: string;
}

class ImageWrapper extends React.Component<Props> {
  state = {
    showDetails: false,
  };

  handleCloseIconClick = () => {
    this.setState({ showDetails: false });
  };

  render() {
    const { title, subtitle, fullwidth, highres, ...fluid } = this.props;
    fluid.aspectRatio = parseFloat(fluid.aspectratio);

    return (
      <Root fullwidth={fullwidth === "true"}>
        <Fade bottom>
          {fullwidth === "true" ? (
            <Img
              alt={title}
              // @ts-ignore
              fluid={{ ...fluid }}
              // data-zoom-src={highres !== 'undefined' ? highres : fluid.src}
            />
          ) : (
            <Zoom wrapStyle={{ display: "block" }}>
              <Img
                alt={title}
                // @ts-ignore
                fluid={{ ...fluid }}
                // data-zoom-src={highres !== 'undefined' ? highres : fluid.src}
              />
            </Zoom>
          )}
        </Fade>

        {this.state.showDetails && (
          <>
            <DetailsContainer>
              <Details>
                {title !== "undefined" && <Title>{title}</Title>}
                {subtitle !== "undefined" && <Para>{subtitle}</Para>}
              </Details>
            </DetailsContainer>
            <CloseIconContainer onClick={this.handleCloseIconClick}>
              <Icon type="close" style={{ fontSize: 35 }} />
            </CloseIconContainer>
          </>
        )}
      </Root>
    );
  }
}

export default ImageWrapper;

interface RootProps {
  fullwidth?: boolean;
}

// eslint-disable-next-line
const Root = styled.span<RootProps>`
  display: block;
  width: ${props => (props.fullwidth ? "100%" : "50%")};
  padding: ${props =>
    props.fullwidth
      ? `${space[6]}px 0`
      : `${space[6]}px ${space[6]}px ${space[6]}px 0`};

  &:nth-of-type(even) {
    padding-right: 0;
    padding-left: ${props => (props.fullwidth ? 0 : `${space[6]}px`)};
  }

  @media (max-width: ${breakpoints[2]}) {
    width: 100%;
    padding: ${space[5]}px 0;

    &:nth-of-type(even) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (max-width: ${breakpoints[1]}) {
    padding: ${space[4]}px 0;
  }
`;
const DetailsContainer = styled("span")`
  position: fixed;
  padding: 2rem 4rem;
  width: 100%;
  bottom: 0;
  left: 0;
  max-width: 400px;
  overflow: hidden;
  z-index: 2;

  &:before {
    content: "";
    background: inherit;
    position: absolute;
    left: -25px;
    right: 0;
    top: -25px;
    bottom: 0;
    box-shadow: inset 0 0 0 3000px rgba(250, 250, 250, 0.8);
    filter: blur(10px);
  }
`;
const Details = styled("span")`
  position: relative;
  display: block;
  text-align: left;
`;
const CloseIconContainer = styled("button")`
  background: none;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  margin: 4rem 4rem 0 0;
  z-index: 1;
`;
