import * as React from "react";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import { Flex, Box } from "rebass";
import { Divider, Icon, Button, Tooltip } from "antd";
import rehypeReact from "rehype-react";
import ReactPlayer from "react-player";
// @ts-ignore
import Fade from "react-reveal/Fade";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Title, Para, PrimaryButton } from "../components/basic";
import ImageWrapper from "../components/casestudy/ImageWrapper";
import styled from "styled-components";
import { breakpoints } from "../theme";
import FollowIcons from "../components/FollowIcons";

interface Props {
  data: {
    markdownRemark: {
      htmlAst: any;
      frontmatter: {
        cover: {
          childImageSharp: {
            fluid: any;
          };
        };
        video: string;
        name: string;
        service: Array<{
          frontmatter: {
            name: string;
          };
        }>;
        startDate: string;
        endDate: string;
        description: string;
        organization: {
          frontmatter: {
            name: string;
            description: string;
          };
        };
        tech: Array<string>;
        color: Array<string>;
        next: Array<{
          frontmatter: {
            title: string;
            name: string;
          };
        }>;
        prev: Array<{
          frontmatter: {
            title: string;
            name: string;
          };
        }>;
        links: Array<{
          link: string;
          title: string;
        }>;
      };
    };
  };
}

const FlexPara = styled(Para)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const PortfolioPage: React.FC<Props> = ({
  data: {
    markdownRemark: { htmlAst, frontmatter },
  },
}) => {
  const renderAst = new rehypeReact({
    // @ts-ignore
    createElement: React.createElement,
    components: {
      //   h2: H2Wrapper,
      //   h3: H3Wrapper,
      //   h4: H4Wrapper,
      //   h5: H5Wrapper,
      //   h6: H6Wrapper,
      p: FlexPara,
      projectimage: ImageWrapper,
      video: props => (
        <Fade bottom>
          <video {...props} style={{ maxWidth: "100%" }} />
        </Fade>
      ),
    },
  }).Compiler;

  const pageSchemaContent = {
    name: frontmatter.name,
    // image: `https://imchetanyadav.com/assets/${
    //   // eslint-disable-next-line
    //   location.pathname.split("/")[2]
    // }.png`,
    // screenshot: `https://imchetanyadav.com/assets/${
    //   // eslint-disable-next-line
    //   location.pathname.split("/")[2]
    // }.png`,
  };

  return (
    <Layout>
      <SEO
        pageTitle={frontmatter.name}
        pageDescription={frontmatter.description}
        pageSchemaContent={pageSchemaContent}
      />
      <Cover
        video={frontmatter.video}
        fluid={frontmatter.cover.childImageSharp.fluid}
      />
      <Flex flexDirection={["column", "column", "row"]}>
        <Box width={[1, 1, 1 / 3]} p={8} pt={9}>
          <Title>{frontmatter.name}</Title>
          <ProjectDetailsPara>{frontmatter.description}</ProjectDetailsPara>
          {/* <ProjectDetailsPara>
            <b>Client</b>
            <br />
            {frontmatter.organization.frontmatter.name}
          </ProjectDetailsPara> */}
          <ProjectDetailsPara>
            <b>Project Date</b>
            <br />
            {frontmatter.startDate}
            {frontmatter.endDate && ` - ${frontmatter.endDate}`}
          </ProjectDetailsPara>
          <ProjectDetailsPara>
            <b>Service</b>
            <br />
            {frontmatter.service.map(s => s.frontmatter.name).join(", ")}
          </ProjectDetailsPara>
          {frontmatter.tech && frontmatter.tech.length > 0 && (
            <ProjectDetailsPara>
              <b>Technology</b>
              <Flex flexWrap="wrap">
                {frontmatter.tech.map(tech => (
                  <Tooltip
                    key={tech}
                    title={tech.split(".")[0]}
                    placement="bottom"
                  >
                    <img
                      src={`/img/technology/${tech}`}
                      alt={tech.split(".")[0]}
                      style={{
                        maxHeight: 30,
                        marginRight: 7,
                        marginTop: 5,
                        marginBottom: 0,
                      }}
                    />
                  </Tooltip>
                ))}
              </Flex>
            </ProjectDetailsPara>
          )}
          {frontmatter.color && frontmatter.color.length > 0 && (
            <ProjectDetailsPara>
              <b>Colors</b>
              <Flex flexWrap="wrap">
                {frontmatter.color.map(color => (
                  <Tooltip key={color} title={color} placement="bottom">
                    <div
                      style={{
                        height: 30,
                        width: 30,
                        border: "2px solid #fff",
                        borderRadius: "50%",
                        background: color,
                        marginRight: 7,
                        marginTop: 5,
                      }}
                    />
                  </Tooltip>
                ))}
              </Flex>
            </ProjectDetailsPara>
          )}
          {frontmatter.links &&
            frontmatter.links.map((l, i) => (
              <a
                key={i}
                target="_blank"
                href={l.link}
                rel="noopener noreferrer"
              >
                <PrimaryButton style={{ marginRight: 10, marginBottom: 10 }}>
                  {l.title}
                </PrimaryButton>
              </a>
            ))}
        </Box>
        <ProjectContentFlex width={[1, 1, 2 / 3]} p={8} pt={9} flexWrap="wrap">
          {renderAst(htmlAst)}
        </ProjectContentFlex>
      </Flex>
      <Divider />
      <Flex
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        alignItems="center"
      >
        {frontmatter.prev ? (
          <StyledLink to={`/p/${frontmatter.prev[0].frontmatter.title}`}>
            <Flex alignItems="center" px={4} py={[6, 6, 0]}>
              <Icon type="arrow-left" />
              <Box px={4}>
                PREVIOUS
                <br />
                <b>{frontmatter.prev[0].frontmatter.name}</b>
              </Box>
            </Flex>
          </StyledLink>
        ) : (
          <span />
        )}
        <Tooltip title="View all projects">
          <StyledLink to="/portfolio">
            <Icon type="appstore" theme="filled" style={{ fontSize: 20 }} />
          </StyledLink>
        </Tooltip>
        {frontmatter.next ? (
          <StyledLink to={`/p/${frontmatter.next[0].frontmatter.title}`}>
            <Flex alignItems="center" px={4} py={[6, 6, 0]}>
              <Box px={4}>
                NEXT
                <br />
                <b>{frontmatter.next[0].frontmatter.name}</b>
              </Box>
              <Icon type="arrow-right" />
            </Flex>
          </StyledLink>
        ) : (
          <span />
        )}
      </Flex>
      <Divider />
      <Flex justifyContent="center">
        <FollowIcons />
      </Flex>
      <div style={{ height: 20, width: "100%", content: "" }} />
    </Layout>
  );
};

export default PortfolioPage;

export const query = graphql`
  query projectQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        cover {
          childImageSharp {
            fluid(maxWidth: 1540) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        video
        title
        name
        tagline
        service {
          frontmatter {
            name
          }
        }
        startDate(formatString: "MMM'YY")
        endDate(formatString: "MMM'YY")
        description
        organization {
          frontmatter {
            name
            description
          }
        }
        tech
        color
        next {
          frontmatter {
            title
            name
          }
        }
        prev {
          frontmatter {
            title
            name
          }
        }
        links {
          icon
          title
          link
        }
      }
    }
  }
`;

const ProjectDetailsPara = styled(Para)`
  font-size: 18px;
  line-height: 28px;
  color: rgba(132, 137, 145, 1);

  & > b {
    color: ${props => props.theme.colors.foreground};
    font-weight: normal;
  }
`;

const ProjectContentFlex = styled(Flex)`
  & > div {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  color: rgba(132, 137, 145, 1);
  & b {
    font-size: 18px;
    font-weight: normal;
    color: ${props => props.theme.colors.foreground};
  }

  @media (max-width: ${breakpoints[1]}) {
    text-align: center;

    & > div > i {
      display: none;
    }
  }
`;

interface CoverProps {
  video?: string;
  fluid?: any;
}

const Cover: React.FC<CoverProps> = ({ video, fluid }) => {
  const [isVideoStarted, updateIsVideoStarted] = React.useState(false);

  return (
    <Box style={{ height: "80vh", position: "relative" }}>
      {video && (
        <ReactPlayer
          url={video}
          // loop
          light={true}
          width="100%"
          height="100%"
          playing
          muted
          controls
          config={{
            youtube: {
              playerVars: { rel: 0 },
            },
          }}
          playIcon={
            <Button
              shape="circle"
              icon="caret-right"
              size="large"
              style={{ marginLeft: 15, zIndex: 1000 }}
              onClick={() => updateIsVideoStarted(true)}
            />
          }
          onPlay={() => {
            updateIsVideoStarted(true);
          }}
        />
      )}
      {fluid && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: isVideoStarted ? "none" : "block",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Img
            fluid={fluid}
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
      )}
    </Box>
  );
};
